<template>
  <v-dialog v-model="dialog" width="500" @click:outside="redirect">
    <v-card>
      <v-card-title class="primary--text px-4 py-1 text-body-1">
        {{ $t('auxiliaryTable.confirm') }}
        <v-spacer />
      </v-card-title>
      <v-divider />
      <v-card-text class="text-body-1 mt-4">
        {{ $t('common.bookkeepingConfirmation') }}
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          class="text-capitalize"
          outlined
          small
          @click="redirect"
        >
          {{ $t('common.ok') }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ReceiptBookkeepingDialog",
  props: ['dialog', 'customer'],
  methods: {
    redirect() {
      this.$router.push({ path: '/customer/CustomerAction/' + this.customer.id})
    }
  }
}
</script>