<template>
  <div>
    <v-dialog
      v-model="receiptInvoicesDialog"
      @click:outside="$emit('update:receiptInvoicesDialog', false);"
      max-width="70%"
    >
      <v-card>
        <v-card-title class="pa-2">
          <v-spacer />
          <v-btn
            color="primary"
            small
            :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
            :loading="loading"
            @click="updateInvoiceForReceipt"
          >
            {{$t('receipt.save')}}
          </v-btn>
          <v-btn
            icon
            @click="$emit('update:receiptInvoicesDialog', false);"
          >
            <v-icon color="primary darken-1">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-h5 px-4">
          <!-- <FormSkeleton v-if="contactId && Object.keys(contactById).length <= 0"/> -->
          <v-form
            ref="formInvoice"
            v-model="validInvoice"
            lazy-validation
            :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
          >
            <Alert :alert.sync="alert" :message="message" />
            <v-row>
              <v-col cols="12">
                <v-card
                  class="mx-auto"
                  flat
                >
                  <v-card-title class="py-1 primary--text">
                    <v-icon color="primary">mdi-cart-plus</v-icon>
                    <h6 class="ms-2 align-self-center font-weight-regular">{{$t('invoice.invoiceList')}}</h6>
                    <v-btn
                      v-if="updatedButton === 'updatedDocuments'"
                      class="mx-10"
                      color="primary"
                      small
                      :loading="loading"
                      :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
                      @click="newUpdate"
                    >
                      {{$t('receipt.newUpdate')}}
                    </v-btn>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row :dense="true">
                      <v-col cols="12">
                        <v-simple-table
                          dense
                          class="custom-table elevation-1 item-table"
                          fixed-header
                          height="250"
                        >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th :style="{width: '140px'}">
                                  {{ $t('receipt.field.customer') }}
                                </th>
                                <th :style="{width: '100px'}">
                                  {{ $t('invoice.invoice') }}
                                </th>
                                <th :style="{width: '100px'}">
                                  {{ $t('receipt.from') }}
                                </th>
                                <th class="text-center" :style="{width: '100px'}">
                                  {{ $t('receipt.field.paymentDate') }}
                                </th>
                                <th v-if="receiptInvoicesType && accessRight.includes('financial') || !receiptInvoicesType" :style="{width: '100px'}">
                                  {{ $t('receipt.originalAmount') }}
                                </th>
                                <th v-if="receiptInvoicesType && accessRight.includes('financial') || !receiptInvoicesType" :style="{width: '100px'}">
                                  {{ $t('receipt.originalBalance') }}
                                </th>
                                <th v-if="receiptInvoicesType && accessRight.includes('financial') || !receiptInvoicesType" class="text-center" :style="{width: '110px'}">
                                  {{ $t('receipt.amountPaid') }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-if="invoiceItems.length <= 0">
                                <td colspan="8" class="text-center">{{$t('common.noDataFound')}}</td>
                              </tr>
                              <tr v-else v-for="(item, index) in invoiceItems" style="vertical-align: bottom" :key="index">
                                <td @dblclick="receiptInvoicesType ? $router.push({ path: '/invoice/invoiceAction/' + item.invoice_id }) : ''">
                                  <span>
                                    {{item.customer_id +'. ' + item.customer_name}}
                                  </span>
                                </td>
                                <td @dblclick="receiptInvoicesType ? $router.push({ path: '/invoice/invoiceAction/' + item.invoice_id }) : ''">
                                  <span>
                                    {{item.invoice_doc_number}}
                                  </span>
                                </td>
                                <td @dblclick="receiptInvoicesType ? $router.push({ path: '/invoice/invoiceAction/' + item.invoice_id }) : ''">
                                  {{profileById.date_format ? formatDateDDMMYYYY(item.invoice_date) : parseDateYYYYMMDD(item.invoice_date)}}
                                </td>
                                <td @dblclick="receiptInvoicesType ? $router.push({ path: '/invoice/invoiceAction/' + item.invoice_id }) : ''">
                                  {{profileById.date_format ? formatDateDDMMYYYY(item.payment_date) : parseDateYYYYMMDD(item.payment_date)}}
                                </td>
                                <td v-if="receiptInvoicesType && accessRight.includes('financial') || !receiptInvoicesType" @dblclick="receiptInvoicesType ? $router.push({ path: '/invoice/invoiceAction/' + item.invoice_id }) : ''">
                                  {{numberWithCommas(item.original_amount)}}
                                </td>
                                <td v-if="receiptInvoicesType && accessRight.includes('financial') || !receiptInvoicesType" @dblclick="receiptInvoicesType ? $router.push({ path: '/invoice/invoiceAction/' + item.invoice_id }) : ''">
                                  {{numberWithCommas(item.original_balance)}}
                                </td>
                                <td v-if="receiptInvoicesType && accessRight.includes('financial') || !receiptInvoicesType">
                                  <v-text-field
                                    class="date-input"
                                    dense
                                    clearable
                                    :name="'amount'+index"
                                    :ref="'amount'+index"
                                    :reverse="locale === 'he' ? false : true"
                                    hide-details="auto"
                                    v-model="item.amount_paid"
                                    :rules="amountValid.concat(v => !v || (v && parseFloat(numberFromCommas(v)) <= parseFloat(numberFromCommas(numberWithCommas(item.original_balance)))&& parseFloat(numberFromCommas(v)) <= parseFloat(numberFromCommas(numberWithCommas(grandTotal)))) || $t('formRules.amountValidMessage', { fieldName: $t('receipt.amountPaid'), type: $t('common.less'), size: numberWithCommas(item.original_balance) }))"
                                    required
                                    @change="amountFill(index)"
                                    @click:clear="amountClear(index)"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                    <v-row :dense="true" class="pt-6">
                      <v-col cols="7">
                      </v-col>
                      <v-divider class="mx-6" vertical/>
                      <v-col cols="4">
                        <v-row dense>
                          <v-col cols="6" class="font-weight-bold">
                            {{$t('receipt.field.grandTotal')}}
                          </v-col>
                          <v-col cols="6" class="font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                            <v-text-field
                              height="18"
                              dense
                              readonly
                              :reverse="locale === 'he' ? false : true"
                              hide-details="auto"
                              v-model="model.grand_total"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-overlay v-if="isProgressCircular">
                <v-icon size="64" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
              </v-overlay>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Alert from "@/components/Alert"
import {formatDateDDMMYYYY, parseDateYYYYMMDD} from '@/utils';

export default {
  name: "ReceiptInvoices",
  props: ["receiptInvoicesDialog", "receiptInvoicesModel", "customerId", "grandTotal", "paidTotal", "receiptInvoicesType", "updatedDocuments", "accessRight", "receiptId", "updatedButton"],
  components: {
    Alert
  },
  data() {
    return {
      alert: false,
      loading: false,
      validInvoice: false,
      model: {
        grand_total: 0.00
      },
      message: '',
      invoiceItems: [],
      paidTotalInvoice: 0,
      isProgressCircular: false,
    }
  },
  computed: {
    amountValid() {
      return [
        v => !v || /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/.test(v) || this.$t('formRules.spaceValidValue'),
        v => !v || (v && parseFloat(this.numberFromCommas(v)) > 0) || this.$t('formRules.amountValidMessage', { fieldName: this.$t('receipt.amountPaid'), type: this.$t('common.greater'), size: 0 }),
        v => !v || (v && String(v).length <= 12) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('receipt.amountPaid'), type: this.$t('common.less'), size: 12 }),
      ];
    },
    ...mapGetters({
      locale: 'locale',
      profileById: 'profile/profileById',
    }),
  },
  watch: {
    async receiptInvoicesDialog(val) {
      if (!val) {
        this.model = {
          grand_total: 0.00
        };
        this.alert = false;
        this.loading = false;
        this.validInvoice = false;
        this.$emit('update:receiptInvoicesType', null);
        this.message = '';
        this.invoiceItems = [];
      } else {
        if (this.customerId && !this.receiptInvoicesType) {
          this.isProgressCircular = true;
          let invoiceList = await this.$store.dispatch('invoice/GetInvoice', {where: 'customer_id,status,status|'+this.customerId+',1,1'})
          this.invoiceItems = [];
          this.isProgressCircular = false;
          
          if (invoiceList && invoiceList.length > 0) {
            // select already paid amount
            this.isProgressCircular = true;
            this.model.grand_total = this.numberWithCommas(parseFloat(this.paidTotal) ?  (parseFloat(this.grandTotal) - parseFloat(this.paidTotal)).toFixed(2) : parseFloat(this.grandTotal).toFixed(2));

            invoiceList.forEach(element => {
              if(element.balance > 0) {
                // select if not already paid amount
                if (this.receiptInvoicesModel && this.receiptInvoicesModel.invoiceData.filter(item => item.invoice_id == element.id).length > 0) {
                  this.invoiceItems.push(this.receiptInvoicesModel.invoiceData.filter(item => item.invoice_id == element.id)[0])
                } else {
                  this.invoiceItems.push(
                    {
                      customer_id: element.customer_id,
                      customer_name: element.customer.name_1,
                      payment_date: element.payment_date,
                      invoice_date: element.invoice_date,
                      invoice_id: element.id,
                      invoice_year: element.invoice_year,
                      original_amount: element.grand_total,
                      original_balance: element.balance,
                      amount_paid: null,
                      employee_id: element.employee_id,
                      invoice_doc_number: element.invoice_document_number
                    }
                  );
                }
              }
            });
            this.isProgressCircular = false;
          }
        } else {
          if (this.updatedDocuments && this.updatedDocuments.length > 0) {
            this.isProgressCircular = true;
            this.updatedDocuments.forEach(item => {
              this.invoiceItems.push(Object.assign({}, {...item, amount_paid: this.numberWithCommas(item.amount_paid)}))
            });
            this.isProgressCircular = false;
          }
        }
      }
    }
  },
  methods: {
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    numberFromCommas(x) {
      return x ? x.toString().replace(/,/g, '') : null;
    },
    numberWithCommas(x, type = 'float') {
      return x ? type === 'float' ? parseFloat(x).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : type === 'float' ? (0).toFixed(2) : x;
    },
    async newUpdate() {
      this.$store.dispatch('updateModule/SetUpdateReceipt', {
        receipt_id: Number(this.receiptId)
      }).then(async () => {
        this.isProgressCircular = true;
        let invoiceList = await this.$store.dispatch('invoice/GetInvoice', {where: 'customer_id,status,status|'+this.customerId+',1,1'})
        this.invoiceItems = [];
        this.isProgressCircular = false;
        invoiceList.forEach(element => {
          if(element.balance > 0) {
            this.invoiceItems.push(
              {
                invoice_id: element.id,
                original_amount: element.grand_total,
                original_balance: element.balance,
                amount_paid: null,
                customer_id: element.customer_id,
                customer_name: element.customer.name_1,
                payment_date: element.payment_date,
                invoice_date: element.invoice_date,
                invoice_year: element.invoice_year,
                employee_id: element.employee_id,
                invoice_doc_number: element.invoice_document_number
              }
            )}
            this.model.grand_total = this.grandTotal
          })
      })
    },
    amountFill(index) {
      const pos = this.$refs['amount'+index][0].$el.querySelector('input').selectionStart + 1;
      this.$nextTick(() => {
        this.$refs['amount'+index][0]?.$el ? this.$refs['amount'+index][0].$el.querySelector('input').selectionEnd = pos : '';
      })
      this.receiptPriceCalculation();
    },
    amountClear(index) {
      this.invoiceItems[index].amount_paid = 0;
      this.receiptPriceCalculation();
    },
    receiptPriceCalculation() {
      let self = this;
      this.paidTotalInvoice = this.invoiceItems.length > 0 ? this.invoiceItems.reduce(function(amount, item){
        return parseFloat(self.numberFromCommas(item.amount_paid)) ? parseFloat(amount) + parseFloat(self.numberFromCommas(item.amount_paid)) : amount + 0; 
      },0).toFixed(2) : 0;
      
      // grand total amount calculation
      if (parseFloat(this.numberFromCommas(this.paidTotalInvoice)) <= parseFloat(this.numberFromCommas(this.grandTotal))) {
        let grand_total = parseFloat(this.paidTotalInvoice) ? (parseFloat(this.grandTotal) - parseFloat(this.paidTotalInvoice)).toFixed(2) : (parseFloat(this.grandTotal)).toFixed(2);
        this.model.grand_total = this.numberWithCommas(grand_total);
      }
    },
    async updateInvoiceForReceipt() {
      if(this.$refs.formInvoice.validate()){
        if(this.paidTotalInvoice > this.grandTotal) {
          this.message = "formRules.lessPaidAmount",
          this.alert = true;
          this.loading = false;
        } else {
          this.loading = true;
          this.model.invoiceData = this.invoiceItems.filter(item => item.amount_paid);
          // if(this.model.invoiceData.length > 0) {
            this.$emit('update:receiptInvoicesModel', this.model);
            // update paidTotal sync prop
            this.$emit('update:paidTotal', this.paidTotalInvoice);
            this.loading = false;
            this.$emit('update:receiptInvoicesDialog', false);
          // } else {
          //   this.message = "formRules.invoiceItemAlert";
          //   this.alert = true;
          //   this.loading = false;
          // } 
        }
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.date-input ::v-deep input  {
  font-size: 12px;
}
.date-input ::v-deep .mdi-close  {
  font-size: 16px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
</style>